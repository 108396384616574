<template>
  <div>
    <ValidationProvider
        v-slot="{ errors }"
        name="คำอธิบาย"
        rules="required"
    >
      <b-form-group
          :invalid-feedback="errors[0]"
          label="คำอธิบาย"
          label-cols-lg="3"
      >
        <b-form-textarea
            id="textarea"
            v-model="form.description"
            :state="errors[0] ? false : null"
            max-rows="6"
            placeholder="Enter something..."
            rows="8"
        ></b-form-textarea>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
        v-slot="{ errors }"
        name="ค่าแนะนำ"
        rules="required|double:2|between:0,100"
    >
      <b-form-group
          :invalid-feedback="errors[0]"
          :state="errors[0] ? false : null"
          label="ค่าแนะนำ"
          label-cols-lg="3"
      >
        <b-input-group append="%">
          <b-form-input
              v-model="form.affiliatePercentage"
              :state="errors[0] ? false : null"
              type="number"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'LottoConfigGeneral',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        description: '',
        affiliatePercentage: 0
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('input', {
          ...this.value,
          ...val
        })
      }
    }
  },
  created() {
    this.form.description = this.value.description
    this.form.affiliatePercentage = this.value.affiliatePercentage
  }
}
</script>

<style scoped>

</style>