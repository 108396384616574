<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSubmit(invalid)">
          <b-card footer-bg-variant="light" no-body>
            <template #header>
              <i class="uil uil-cog mr-1"></i>
              {{ lottoConfigForm.name }}
            </template>

            <b-tabs card>
              <b-tab title="ตั้งค่าทั่วไป">
                <div v-if="isFetchingLottoConfigById" class="text-center">
                  <b-spinner />
                </div>
                <LottoConfigGeneral v-else v-model="lottoConfigForm" />
              </b-tab>

              <b-tab title="ลิมิตการแทง">
                <div v-if="isFetchingLottoConfigById" class="text-center">
                  <b-spinner />
                </div>
                <b-row v-else>
                  <b-col
                      v-for="(betType, index) of orderedBetTypes"
                      :key="index"
                      lg="6"
                  >
                    <LottoConfigBet
                        :value="lottoConfigForm.lottoGameConfigs.find((config) => config.betType === betType)"
                        @input="(e) => lottoConfigForm.lottoGameConfigs[index] = e"
                    />
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="ตั้งค่าเรทจ่าย">
                <div v-if="isFetchingPayoutRate" class="text-center">
                  <b-spinner />
                </div>
                <template v-else>
                  <b-row>
                    <b-col
                        v-for="(item, index) in orderedBetTypes"
                        :key="index"
                        :value="item"
                        class="pb-2"
                        lg="6"
                    >
                      <LottoConfigPayoutRate
                          v-model="payoutRateForm"
                          :bet-type="item"
                          style="height: 100%"
                          @update-payout="e => maximumPayouts[item] = e"
                      />
                    </b-col>
                  </b-row>

                  <div class="mt-3 text-right">
                    จ่ายสูงสุดทั้งหมด: {{ getMaximumPayout().toLocaleString() }} บาท
                  </div>
                </template>
              </b-tab>

              <b-tab v-if="lottoConfigForm.gameType === LottoGameTypeEnum.YEEKEE" title="ตั้งค่ายี่กี">
                <div v-if="isFetchingLottoConfigById" class="text-center">
                  <b-spinner />
                </div>
                <LottoConfigYeekee v-else v-model="lottoConfigForm.lottoGameYeekeeConfig" />
              </b-tab>

              <b-tab v-else title="เลขอั้น">
                <div v-if="isFetchingLimitNumbers" class="text-center">
                  <b-spinner />
                </div>
                <LottoConfigLimitNumber v-else v-model="limitNumbersForm" :bet-types="orderedBetTypes" />
              </b-tab>
            </b-tabs>

            <template #footer>
              <div class="text-right">
                <b-button :to="`/lotto/list?type=${type}`" class="mr-3" variant="white">
                  <i class="uil uil-arrow-left"></i>
                  {{ $t('buttons.back') }}
                </b-button>
                <b-button :disabled="isUpdatingLottoConfigById" type="submit" variant="primary">
                  <i class="uil uil-check"></i>
                  {{ $t('buttons.save') }}
                </b-button>
              </div>
            </template>
          </b-card>
        </form>
      </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {LottoBetTypeEnum, LottoGameTypeEnum} from '@src/constants/lotto';
import LottoConfigGeneral from '@components/lotto/config/general';
import LottoConfigBet from '@components/lotto/config/bet';
import LottoConfigYeekee from '@components/lotto/config/yeekee';
import LottoConfigPayoutRate from '@components/lotto/config/payout-rate';
import LottoConfigLimitNumber from '@components/lotto/config/limit-number';

export default {
  components: {
    LottoConfigLimitNumber,
    LottoConfigPayoutRate,
    LottoConfigYeekee,
    LottoConfigBet,
    LottoConfigGeneral,
  },
  data() {
    return {
      lottoConfigForm: {},
      payoutRateForm: [],
      limitNumbersForm: [],
      isFetching: false,
      maximumPayouts: {}
    }
  },
  computed: {
    ...mapState({
      lottoConfigDetail: (state) => state.lotto.lottoConfigDetail,
      lottoPayoutRate: (state) => state.lotto.lottoPayoutRate,
      lottoLimitNumbers: (state) => state.lotto.lottoLimitNumbers,
      isFetchingLottoConfigById: (state) => state.lotto.isFetchingLottoConfigById,
      isFetchingLimitNumbers: (state) => state.lotto.isFetchingLimitNumbers,
      isUpdatingLottoConfigById: (state) => state.lotto.isUpdatingLottoConfigById,
      isFetchingPayoutRate: (state) => state.lotto.isFetchingPayoutRate,
      isUpdatingLottoGameDetailById: (state) => state.lotto.isUpdatingLottoGameDetailById
    }),
    type() {
      return this.$route.query.type || LottoGameTypeEnum.THAI_GOVERNMENT
    },
    lottoId() {
      return this.$route.params.id
    },
    LottoGameTypeEnum() {
      return LottoGameTypeEnum
    },
    orderedBetTypes() {
      return this.lottoConfigDetail?.allowedBetTypes
          ? Object
              .values(LottoBetTypeEnum)
              .filter((betType) => this.lottoConfigDetail.allowedBetTypes.includes(betType))
          : []
    },
  },
  watch: {
    lottoConfigDetail(val) {
      if(val) {
        this.lottoConfigForm = {...val}
      }
    },
    lottoPayoutRate(val) {
      this.payoutRateForm = val
    },
    lottoLimitNumbers(val) {
      this.limitNumbersForm = val
    }
  },
  created() {
    this.fetchConfig()
  },
  methods: {
    ...mapActions([
        'fetchLottoGameConfigById',
        'updateLottoGameConfigById',
        'updateLottoGameDetailById',
        'getPayoutRateByLottoGameId',
        'getLimitNumbersByLottoGameId',
        'setPayoutRateByGameId',
        'updateYeekeeConfig',
        'updateLimitNumber',
        'notifySuccess'
    ]),
    async fetchConfig() {
      await Promise.all([
        this.getPayoutRateByLottoGameId(this.lottoId),
        this.fetchLottoGameConfigById(this.lottoId),
        this.getLimitNumbersByLottoGameId(this.lottoId)
      ])
      this.$forceUpdate()
    },
    // received update form event emitter from ConfigForm
    onConfigUpdate(configIndex, newConfig) {
      this.lottoConfigForm.lottoGameConfigs[configIndex] = newConfig
    },
    async onSubmit(isInvalid) {
      if(isInvalid) {
        this.$bvToast.toast('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ', {
          variant: 'danger',
          title: 'ผิดพลาด'
        })
        return
      }

      this.isFetching = true
      const result = await Promise.all([
        this.updateLottoGameConfigById({
          id: this.lottoId,
          configs: this.lottoConfigForm.lottoGameConfigs,
        }),
        this.updateLottoGameDetailById({
          id: this.lottoId,
          payload: {
            affiliatePercentage: this.lottoConfigForm.affiliatePercentage,
            description: this.lottoConfigForm.description,
          },
        }),
        this.setPayoutRateByGameId({
          lottoGameId: this.lottoId,
          payload: this.payoutRateForm,
        }),
        this.updateLimitNumber({
          lottoGameId: this.lottoId,
          payload: this.limitNumbersForm,
        }),
        (
            this.lottoConfigForm.gameType === LottoGameTypeEnum.YEEKEE
            ? this.updateYeekeeConfig({
                  lottoGameId: this.lottoId,
                  payload: this.lottoConfigForm.lottoGameYeekeeConfig,
                })
            : true
        )
      ])

      if(!result.includes(false)) {
        this.notifySuccess()
      }
      this.isFetching = false
      await this.fetchConfig()
    },
    getMaximumPayout() {
      return Object
          .entries(this.maximumPayouts)
          .map((entry) => entry[1])
          .reduce((x, y) => x + y, 0)
    }
  },
}
</script>
