<template>
  <div v-if="value">
    <b-card :title="betTypeLabel">
      <ValidationProvider
          v-slot="{ errors }"
          :vid="`min-bet-amount-${form.betType}`"
          name="แทงขั้นต่ำต่อโพย"
          rules="required|double:2|min:0"
      >
        <b-form-group
            :description="`ราคาต่ำสุดที่แทงได้ต่อโพยของประเภท ${betTypeLabel}`"
            :invalid-feedback="errors[0]"
            label="แทงขั้นต่ำต่อโพย"
        >
          <b-form-input
              v-model="form.minBetAmount"
              :state="errors[0] ? false : null"
              type="number"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
          v-slot="{ errors }"
          :vid="`max-bet-amount-${form.betType}`"
          name="แทงสูงสุดต่อโพย"
          rules="required|double:2|min:0"
      >
        <b-form-group
            :description="`ราคามากสุดที่แทงได้ต่อโพยของประเภท ${betTypeLabel}`"
            :invalid-feedback="errors[0]"
            label="แทงสูงสุดต่อโพย"
        >
          <b-form-input
              v-model="form.maxBetAmount"
              :state="errors[0] ? false : null"
              type="number"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
          v-slot="{ errors }"
          :vid="`max-bet-per-number-${form.betType}`"
          name="แทงสูงสุดต่อเลข"
          rules="required|double:2|min:0"
      >
        <b-form-group
            :description="`ราคามากสุดที่แทงได้ต่อเลข (รวมกันทุกโพย) ของประเภท ${betTypeLabel}`"
            :invalid-feedback="errors[0]"
            label="แทงสูงสุดต่อเลข"
        >
          <b-form-input
              v-model="form.maxBetAmountPerNumber"
              :state="errors[0] ? false : null"
              type="number"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
    </b-card>
  </div>
</template>

<script>
import {getBetTypeLabel} from '@src/views/main/lotto/utils'

export default {
  name: 'LottoConfigBet',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    betTypeLabel() {
      return getBetTypeLabel(this.form.betType)
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.form = { ...this.value }
  },
  methods: {
    getBetTypeLabel,
  },
}
</script>
