<template>
  <div>
    <ValidationProvider v-slot="{ errors }" rules="required|double:2|min:0">
      <b-form-group
          :invalid-feedback="errors[0]"
          description="ล็อคเปอร์เซ็นต์กำไรที่จะได้รับ คำนวณจากยอดแทงทั้งหมด"
          label="ตั้งกิน"
      >
        <b-input-group append="%">
          <b-form-input
              v-model="form.incomePercentage"
              :state="errors[0] ? false : null"
              step="1"
              type="number"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <hr />
    <h5>
      ล็อคเลข
      <small class="text-danger ml-2">*** หากล็อคเลข อาจจะทำให้ไม่ได้รับกำไรตามที่ตั้งกินไว้ ***</small>
    </h5>

    <b-row class="mt-2">
      <b-col lg="6">
        <ValidationProvider v-slot="{ errors }" :rules="isFixThreeResult ? 'required|numeric|length:3' : ''">
          <b-form-group
              :invalid-feedback="errors[0]"
          >
            <template #label>
              <b-form-checkbox id="isFixThreeResult" v-model="isFixThreeResult" class="d-inline" switch />
              <label for="isFixThreeResult">ล็อคเลขสามตัว</label>
            </template>

            <b-form-input
                v-model="form.fixThreeResult"
                :disabled="!isFixThreeResult"
                :state="errors[0] ? false : null"
                maxlength="3"
                type="text"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <b-col lg="6">
        <ValidationProvider v-slot="{ errors }" :rules="isFixTwoResult ? 'required|numeric|length:2' : ''">
          <b-form-group
              :invalid-feedback="errors[0]"
          >
            <template #label>
              <b-form-checkbox id="isFixTwoResult" v-model="isFixTwoResult" class="d-inline" switch />
              <label for="isFixTwoResult">ล็อคเลขสองตัว</label>
            </template>

            <b-form-input
                v-model="form.fixTwoResult"
                :disabled="!isFixTwoResult"
                :state="errors[0] ? false : null"
                maxlength="2"
                type="text"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'LottoConfigYeekee',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      form: {},
      isFixThreeResult: false,
      isFixTwoResult: false,
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.emitData(val)
      }
    },
    isFixTwoResult() {
      this.emitData(this.form)
    },
    isFixThreeResult() {
      this.emitData(this.form)
    }
  },
  created() {
    const value = this.value || {
      fixThreeResult: null,
      fixTwoResult: null,
      incomePercentage: 20,
    }

    if(value.fixThreeResult) {
      this.isFixThreeResult = true
    }
    if(value.fixTwoResult) {
      this.isFixTwoResult = true
    }
    this.form = {...value}
  },
  methods: {
    emitData(val) {
      this.$emit('input', {
        incomePercentage: val.incomePercentage,
        fixThreeResult: this.isFixThreeResult ? val.fixThreeResult : null,
        fixTwoResult: this.isFixTwoResult ? val.fixTwoResult : null,
      })
    }
  }
}
</script>
