<template>
  <div>
    <div class="alert alert-info">
      <i class="uil uil-info-circle"></i>
      รายการเลขอั้นรอบล่าสุด
    </div>
    <b-row v-for="(limitNumber, index) in form" :key="index" class="mb-2">
      <b-col>
        <ValidationProvider
            v-slot="{ errors }"
            :vid="`bet-type-${index}`"
            name="ประเภทแทง"
            rules="required"
        >
          <b-form-select
              v-model="limitNumber.betType"
              :options="betTypeOption"
              :state="errors[0] ? false : null"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>-- กรุณาเลือกประเภทแทง ---</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>

      <b-col>
        <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: true,
              numeric: true,
              length: getNumberLengthByBetType(limitNumber.betType),
              unique: getNumbersFromBetType(limitNumber.betType, index)
            }"
            :vid="`bet-number-${index}`"
            name="เลขอั้น"
        >
          <b-input-group class="is-invalid" prepend="เลขอั้น">
            <b-input
              v-model="limitNumber.betNumber"
              :disabled="!limitNumber.betType"
              :maxlength="getNumberLengthByBetType(limitNumber.betType)"
              :state="errors[0] ? false : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>

      <b-col>
        <ValidationProvider
            v-slot="{ errors }"
            :rules="`required|numeric|min:0`"
            :vid="`payout-rate-${index}`"
            name="ราคาจ่าย"
        >
          <b-input-group append="บาท" class="is-invalid" prepend="ราคา">
            <b-input
                v-model="limitNumber.payoutRate"
                :state="errors[0] ? false : null"
                maxlength="7"
                step="1"
                type="number"
            />
          </b-input-group>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>

      <b-col class="text-center" cols="1">
        <b-button variant="danger" @click.prevent="remove(index)">
          <i class="uil uil-trash"></i>
        </b-button>
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button variant="success" @click.prevent="add">
        <i class="uil uil-plus"></i>
        เพิ่ม
      </b-button>
    </div>
  </div>
</template>

<script>
import {getBetTypeLabel, getNumberLengthByBetType} from '@src/views/main/lotto/utils';

export default {
  name: 'LottoConfigLimitNumber',
  props: {
    value: {
      type: Array,
      required: true
    },
    betTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: []
    }
  },
  computed: {
    betTypeOption() {
      return this.betTypes.map((betType) => ({ value: betType, text: this.getBetTypeLabel(betType)}))
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.form = this.value.map((value) => ({ ...value }))
  },
  methods: {
    getBetTypeLabel,
    getNumberLengthByBetType,
    add() {
      this.form.push({
        betType: null,
        betNumber: '',
        payoutRate: '',
      })
    },
    remove(index) {
      this.form.splice(index, 1)
    },
    getNumbersFromBetType(betType, excludeIndex) {
      return this
          .form
          .filter((item, i) => item.betType === betType && i !== excludeIndex)
          .map((item) => item.betNumber)
          .join(',')
    }
  }
}
</script>

<style scoped>

</style>