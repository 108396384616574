var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._l((_vm.form),function(limitNumber,index){return _c('b-row',{key:index,staticClass:"mb-2"},[_c('b-col',[_c('ValidationProvider',{attrs:{"vid":("bet-type-" + index),"name":"ประเภทแทง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.betTypeOption,"state":errors[0] ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- กรุณาเลือกประเภทแทง ---")])]},proxy:true}],null,true),model:{value:(limitNumber.betType),callback:function ($$v) {_vm.$set(limitNumber, "betType", $$v)},expression:"limitNumber.betType"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            numeric: true,
            length: _vm.getNumberLengthByBetType(limitNumber.betType),
            unique: _vm.getNumbersFromBetType(limitNumber.betType, index)
          },"vid":("bet-number-" + index),"name":"เลขอั้น"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"is-invalid",attrs:{"prepend":"เลขอั้น"}},[_c('b-input',{attrs:{"disabled":!limitNumber.betType,"maxlength":_vm.getNumberLengthByBetType(limitNumber.betType),"state":errors[0] ? false : null,"type":"text"},model:{value:(limitNumber.betNumber),callback:function ($$v) {_vm.$set(limitNumber, "betNumber", $$v)},expression:"limitNumber.betNumber"}})],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required|numeric|min:0","vid":("payout-rate-" + index),"name":"ราคาจ่าย"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"is-invalid",attrs:{"append":"บาท","prepend":"ราคา"}},[_c('b-input',{attrs:{"state":errors[0] ? false : null,"maxlength":"7","step":"1","type":"number"},model:{value:(limitNumber.payoutRate),callback:function ($$v) {_vm.$set(limitNumber, "payoutRate", $$v)},expression:"limitNumber.payoutRate"}})],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_c('i',{staticClass:"uil uil-trash"})])],1)],1)}),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}}},[_c('i',{staticClass:"uil uil-plus"}),_vm._v(" เพิ่ม ")])],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert alert-info"},[_c('i',{staticClass:"uil uil-info-circle"}),_vm._v(" รายการเลขอั้นรอบล่าสุด ")])}]

export { render, staticRenderFns }