<template>
  <b-card :title="betTypeLabel">
    <b-row v-for="(item, i) in form" :key="i" class="mb-2">
      <b-col cols="11">
        <b-row>
          <b-col cols="6">
            <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|double|${getPayoutRateRule(i)}`"
                :vid="`payout-rate-${betType}-${i}`"
                name="เรทจ่าย"
            >
              <b-input-group class="is-invalid" prepend="เรทจ่าย">
                <b-form-input
                    :ref="`payout-rate-${betType}-${i}`"
                    v-model="item.payoutRate"
                    :state="errors[0] ? false : null"
                    step="0.1"
                    type="number"
                />
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>

          <b-col cols="6">
            <ValidationProvider
                v-slot="{ errors }"
                :vid="`accept-amount-${betType}-${i}`"
                name="ยอดแทง"
                rules="required|double|min:0"
            >
              <b-input-group append="บาท" class="is-invalid" prepend="รับ">
                <b-form-input
                    :ref="`accept-amount-${betType}-${i}`"
                    v-model="item.acceptAmount"
                    :state="errors[0] ? false : null"
                    step="0.1"
                    type="number"
                />
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>

        </b-row>
      </b-col>

      <b-col class="text-center" cols="1">
        <b-button :disabled="i === 0" class="px-2 mt-1" size="sm" type="button" variant="danger" @click.prevent="remove(i)">
          <i class="uil uil-trash"></i>
        </b-button>
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button size="sm" variant="success" @click.prevent="add">
        <i class="uil uil-plus"></i>
        เพิ่ม
      </b-button>
    </div>

    <template #footer>
      <span>
        จ่ายสูงสุด: {{ maximumPayout ? maximumPayout.toLocaleString() : '' }} บาท
      </span>
    </template>
  </b-card>
</template>

<script>
import {getBetTypeLabel} from '@src/views/main/lotto/utils';

export default {
  name: 'LottoConfigPayoutRate',
  props: {
    betType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: []
    }
  },
  computed: {
    betTypeLabel() {
      return this.getBetTypeLabel(this.betType)
    },
    lastIndex() {
      return this.form.length - 1
    },
    maximumPayout() {
      return this
        .form
        .reduce((x, y) => x + (+y.payoutRate * +y.acceptAmount), 0)
    },
    filteredValue() {
      return this.value.filter((v) => v.betType === this.betType)
    }
  },
  watch: {
    maximumPayout(val) {
      this.$emit('update-payout', val)
    },
    form: {
      deep: true,
      handler(val) {
        const emitData = []
        emitData.push({
          id: val[0].id,
          betType: this.betType,
          startAmount: 0,
          payoutRate: val[0].payoutRate
        })

        let sumAcceptAmount = 0
        val
          .forEach((v, index) => {
            sumAcceptAmount += +v.acceptAmount
            emitData.push({
              id: this.filteredValue[index + 1]?.id,
              betType: this.betType,
              startAmount: sumAcceptAmount,
              payoutRate: +val[index + 1]?.payoutRate || 0
            })
          })

        const concatData = this.value.filter((payoutRate) => payoutRate.betType !== this.betType).concat(emitData)
        this.$emit('input', concatData)
      }
    }
  },
  created() {
    this.updateForm()
  },
  methods: {
    getBetTypeLabel,
    updateForm() {
      let sumAcceptAmount = 0
      this.form = this.filteredValue
          .slice(0, -1)
          .map((item, index) => {
            const tempAmount = sumAcceptAmount
            sumAcceptAmount += +this.filteredValue[index + 1].startAmount - sumAcceptAmount
            return {
              id: item.id,
              acceptAmount: +this.filteredValue[index + 1].startAmount - tempAmount,
              payoutRate: +item.payoutRate
            }
          })

      if (!this.form.length) {
        this.add()
      }
    },
    add() {
      this
        .form
        .push({
          acceptAmount: '',
          payoutRate: '',
        })

      this.$nextTick(() => {
        const field = this.$refs[`payout-rate-${this.betType}-${+this.lastIndex}`]
        if (field) {
          field[0].focus()
        }
      })
    },
    remove(i) {
      if (i > 0) {
        this.form.splice(i, 1)
      }
    },
    getPayoutRateRule(index) {
      const prevItem = this.form[index - 1]
      const nextItem = this.form[index + 1]

      if (index === 0) {
        return `min_value:${+(nextItem?.payoutRate || 0) + 1}`
      } else if (index > 0 && index < this.lastIndex) {
        return `between:${+nextItem.payoutRate + 1},${+prevItem.payoutRate - 1}`
      } else {
        return 'min_value:1'
      }
    },
  }
}
</script>

<style scoped>

</style>